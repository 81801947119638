<template>
  <div class="services">
    <div class="services-landing">
      <img id="conciergeImage" src="../assets/concierge-cover.jpeg" alt="Hvar island ">
      <div class="services-landing-overlay">
        <div class="services-landing-inner">
          <h1>Hvar Island Concierge Service</h1>
        </div>
      </div>
    </div>
    <div class="services-inner-content" v-for="data in myConcierge" v-bind:key="data.id">
      <div class="inner-image">
        <img v-lazy="data.image" alt="Hvar island concierge">
      </div>
      <div class="inner-text">
        <h2>{{ data.title }}</h2>
        <div class="line"></div>
        <p v-html="data.content"></p>

        <button class="services-button" @click="show()">Make Enquiry</button>
      </div>
    </div>

  </div>

</template>

<script>
import concierge from '../data/concierge.json'
import Modal from "@/components/Modal"

export default {

  metaInfo() {
    return{
    title: 'Hvar Island Concierge Services | Hvar Villas, Transfers, Private Tours ',
    meta: [
      { name: "robots", content: "index,follow" },
      {
        name: 'description',
        content: 'If you dont want to spend too much time searching internet for the best things in Hvar, Hvar Island Concierge Services, will take care of it for you. Give us a call.'
      }

    ],
    link: [
      {rel: 'favicon', href: 'favicon.ico'}
    ]
    }
  },
  data() {
    return {
      myConcierge: concierge,
      loaded: false
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },

    show() {
      this.$modal.show(
          Modal
      );
    },
  }

}
</script>